<template>
  <AddEditReward :isnew="isnew" :duplicat="duplicat" />
</template>

<script>

import AddEditReward from "../../components/rewards/Add-Edit-Reward.vue"

export default {
  components: { AddEditReward },
  data() {
    return {
      isnew: true,
      duplicat: true,
    };
  },
};
</script>

<style></style>
<template>
  <div class="card border-radius-lg">
    <div class="heder_logo card-header position-relative p-0 border-radius-lg"
      :style="{ backgroundImage: `url(${bgImage})` }">
      <a href="javascript:;" class="logo d-flex align-items-center justify-content-center border-radius-lg">
        <img :src="logoImage" class="img-fluid border-radius-lg">
      </a>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col">
          <span class="text-gradient text-warning text-uppercase font-weight-bold">{{ price }}</span>
        </div>
        <div class="col-4" style="text-align : right">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="active"
              style="margin-left: auto; margin-right: 0; float: none; "
              v-on:input="changerewardstate()"
              v-bind:disabled="!action"
            />
          </div>
        </div>
      </div>
      
      <h5 class="card-title h5 d-block text-darker">{{ title }}</h5>
      <p class="card-description mb-2">{{ description }}</p>

      <h6 class="card-title h6 d-block text-darker mb-0">How it work?</h6>
      <div class="card-description mb-2">
        <ol>
          <li v-for="(item, index) in how" :key="index">{{ item }}</li>
        </ol>
      </div>

      <h6 class="card-title h6 d-block text-darker mb-0">Terms & Condition</h6>
      <div class="card-description mb-2" v-html="tandc"></div>

      <h6 class="card-title h6 d-block text-darker mb-0">Required Fileds</h6>
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Placeholder</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Min</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Max</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in require" :key="index">
            <td class="align-middle text-secondary text-xs font-weight-bold">{{ item.title }}</td>
            <td class="align-middle text-secondary text-xs font-weight-bold">{{ item.placeholder }}</td>
            <td class="align-middle text-secondary text-xs font-weight-bold">{{ item.type }}</td>
            <td class="align-middle text-secondary text-xs font-weight-bold">{{ item.min }}</td>
            <td class="align-middle text-secondary text-xs font-weight-bold">{{ item.max }}</td>
          </tr>
        </tbody>
      </table>


      <div class="actionbtn" v-if="action">
        <div class="row">
          <div class="col">
            <button type="button" class="btn bg-gradient-secondary w-100 mb-0 mt-3" @click="editreward">Edit</button>
          </div>
          <div class="col">
            <button type="button" class="btn bg-gradient-info w-100 mb-0 mt-3"
              @click="duplicatReward">Duplicate</button>
          </div>
          <div class="col">
            <button type="button" class="btn bg-gradient-danger w-100 mb-0 mt-3" @click="deletereward">Delete</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SwlAlerts from "../../swlalerts.js";
import firebase from "firebase";

export default {
  props: [
    "id",
    "logoImage",
    "bgImage",
    "title",
    "description",
    "how",
    "tandc",
    "price",
    "active",
    "require",
    "action",
  ],
  data() {
    return {};
  },
  methods: {
    deletereward() {
      SwlAlerts.swlloading();
      const db = firebase.firestore();
      const storage = firebase.storage();

      var user = firebase.auth().currentUser;

      firebase
        .storage()
        .ref("/rewards/" + user.uid + "/" + this.id + ".jpg")
        .delete()
        .then(() => {
          db.collection("rewards")
            .doc(this.id)
            .delete()
            .then(() => {
              SwlAlerts.swldone("Reward Deleted!");
            })
            .catch((error) => {
              console.log(error);
              SwlAlerts.swlerror(error.message);
            });
        })
        .catch((error) => {
          SwlAlerts.swlerror(error.message);
        });
    },

    changerewardstate() {
      SwlAlerts.swlloading();

      const db = firebase.firestore();

      db.collection("rewards")
        .doc(this.id)
        .update({
          active: !this.active,
        })
        .then(() => {
          SwlAlerts.swldone("Reward Status Changed!");
        })
        .catch((error) => {
          console.log(error);
          SwlAlerts.swlerror(error.message);
        });
    },

    editreward() {
      this.$router.push({ path: `/rewards/edit-reward/${this.id}` });
    },

    duplicatReward() {
      this.$router.push({ path: `/rewards/duplicat-reward/${this.id}` });
    },
  },
};
</script>

<style scoped>
img {
  max-width: 100%;
}

.heder_logo {
  background-size: cover;
  background-position: center;
}

.logo {
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 150px;
}
</style>
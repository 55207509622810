<template>
    <div class="card mt-3" v-if="!loading">
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col">
                    <div class="d-flex px-2 py-1">
                        <div>
                            <img src="{{ user.photoURL }}" alt="" class="avatar avatar-sm me-3">
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-xs">{{ user.displayName }}</h6>
                            <p class="text-xs mt-1 mb-0">{{ user.email }}</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <p class="text-xs text-secondary mb-0">User Remaning Coins</p>
                    <h6 class="mt-1 text-xs"><img width="16" src="@/assets/img/coin.svg" alt /> {{ user.coins }}</h6>
                </div>
                <div class="col">
                    <p class="text-xs text-secondary mb-0">Time</p>
                    <h6 class="mt-1 text-xs">{{ timeright() }}</h6>
                </div>
                <div class="col">
                    <p class="text-xs text-secondary mb-0">Status</p>
                    <span v-if="request.status == 'Pending'" class="badge bg-gradient-warning">Panding</span>
                    <span v-if="request.status == 'Success'" class="badge bg-gradient-success">Success</span>
                    <span v-if="request.status == 'Failed'" class="badge bg-gradient-danger">Failed</span>
                </div>
                <div class="col">
                    <p class="text-xs text-secondary mb-0">Status</p>
                    <div class="row">
                        <div class="col" v-if="request.status != 'Pending'">
                            <button @click="toggelRequestPendingModal" class="btn btn-sm bg-gradient-warning w-100 mb-0">Panding</button>
                        </div>
                        <div class="col" v-if="request.status != 'Success'">
                            <button class="btn btn-sm bg-gradient-success w-100 mb-0"
                                @click="toggelRequestSuccessModal">Success</button>
                        </div>
                        <div class="col" v-if="request.status != 'Failed'">
                            <button class="btn btn-sm btn-danger w-100 mb-0">Failed</button>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col">
                    <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">Reward Details</h6>
                    <div class="row align-items-center">
                        <div class="col">
                            <div class="heder_logo position-relative p-0 border-radius-lg"
                                :style="{ backgroundImage: `url(${reward.bgImage})` }">
                                <a href="javascript:;"
                                    class="logo border-radius-lg d-flex align-items-center justify-content-center  p-2">
                                    <img :src="reward.logoImage" class="img-fluid border-radius-lg">
                                </a>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col-6">
                                    <p class="text-xs text-secondary mb-0">Title</p>
                                    <h6 class="mt-1 text-xs">{{ reward.title }}</h6>
                                </div>
                                <div class="col-6">
                                    <p class="text-xs text-secondary mb-0">Price</p>
                                    <h6 class="mt-1 text-xs"><img width="16" src="@/assets/img/coin.svg" alt /> {{
                                        reward.price }}
                                    </h6>
                                </div>
                                <div class="col-6">
                                    <p class="text-xs text-secondary mb-0">Type</p>
                                    <h6 class="mt-1 text-xs">{{ reward.type }}</h6>
                                </div>
                                <div class="col-6">
                                    <p class="text-xs text-secondary mb-0">Days</p>
                                    <h6 class="mt-1 text-xs">{{ reward.days }}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">Exrta Details</h6>
                    <div class="row align-items-center">
                        <div class="col-6" v-for="(item, index) in request.data" :key="index">
                            <p class="text-xs text-secondary mb-0">{{ item.title }}</p>
                            <h6 class="mt-1 text-xs">{{ item.value }}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-2">User Message</h6>
            <div v-if="txn.status == 'In Review'" class="alert alert-warning" role="alert">
                <strong>{{ txn.status }}!</strong> {{ txn.message }}
            </div>
            <div v-if="txn.status == 'Success'" class="alert alert-success" role="alert">
                <strong>{{ txn.status }}!</strong> {{ txn.message }}
            </div>
            <div v-if="txn.status == 'Failed'" class="alert alert-danger" role="alert">
                <strong>{{ txn.status }}!</strong> {{ txn.message }}
            </div>

        </div>
    </div>
    <RequestSuccessModal :active="requestSuccessModal" :txnID="request.txnID" :rrID="request.id" @toggelRequestSuccessModal="toggelRequestSuccessModal" />
    <RequestPendingModal :active="requestPendingModal" :txnID="request.txnID" :rrID="request.id" @toggelRequestPendingModal="toggelRequestPendingModal" />
</template>

<script>





import firebase from "firebase";
import SwlAlerts from "../../swlalerts.js";
import TimeRight from "../../timeright.js";
import RequestSuccessModal from "./Request-Success.vue";
import RequestPendingModal from "./Request-Pending.vue";


export default {
    props: ["request"],
    components: { RequestSuccessModal, RequestPendingModal },
    data() {
        return {
            loading: true,
            user: {},
            reward: {},
            txn: {},
            requestSuccessModal: false,
            requestPendingModal: false,
        };
    },
    created() {
        this.getuser();
    },
    methods: {
        timeright() {
            var time = TimeRight.datemodifiler(this.request.time);
            return time;
        },
        async getuser() {
            const db = firebase.firestore();

            db.collection("users").doc(this.request.uid).onSnapshot((doc) => {
                this.user = doc.data();
                console.log("User updated:", this.user);
                this.getReward();
            }, (error) => {
                console.error("Error fetching user data:", error);
            });


        },
        async getReward() {
            const db = firebase.firestore();
            db.collection("rewards").doc(this.request.rid).onSnapshot((doc) => {
                this.reward = doc.data();
                console.log("Reward updated:", this.reward);
                this.getTxn();
            }, (error) => {
                console.error("Error fetching reward data:", error);
            });

        },
        async getTxn() {
            const db = firebase.firestore();
            db.collection("transaction").doc(this.request.txnID).onSnapshot((doc) => {
                this.txn = doc.data();
                console.log("Transaction updated:", this.txn);
                this.loading = false; // Loading complete
            }, (error) => {
                console.error("Error fetching transaction data:", error);
            });
        },

        async toggelRequestSuccessModal() {
            console.log("toggelRequestSuccessModal");
            this.requestSuccessModal = !this.requestSuccessModal;
        },
        async toggelRequestPendingModal() {
            console.log("toggelRequestPendingModal");
            this.requestPendingModal = !this.requestPendingModal;
        },



    },
};
</script>

<style scoped>
img {
    max-width: 100%;
}

.heder_logo {
    background-size: cover;
    background-position: center;
}

.logo {
    background-color: rgba(0, 0, 0, 0.3);
}
</style>
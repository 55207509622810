<template>
  <div class="row">
    <div class="col-xl-8 mb-3">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="savereward">
            <div class="row">

              <div class="col-6">
                <label>Brand Logo</label>
                <div class="mb-3">
                  <input type="file" class="form-control" placeholder="Brand Logo" @change="logoImageFun"
                    accept="image/png" />
                </div>
              </div>

              <div class="col-6">
                <label>Background Image</label>
                <div class="mb-3">
                  <input type="file" class="form-control" placeholder="Background Image" @change="bgImageFun"
                    accept="image/jpg, image/jpeg" />
                </div>
              </div>

              <div class="col-12">
                <label>Title</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Title" v-model="title" required />
                </div>
              </div>

              <div class="col-12">
                <label>Description</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Description" v-model="description" required />
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <div class="col">
                    <label>How it work?</label>
                  </div>
                  <div class="col text-right">
                    <button type="button" class="btn btn-success btn-sm" @click="addItemHow">Add</button>
                  </div>
                </div>
                <div v-for="(item, index) in how" :key="index" class="row">
                  <div class="col">
                    <input type="text" class="form-control" :placeholder="`Enter text for item ${index + 1}`"
                      v-model="how[index]" required />
                  </div>
                  <div class="col-auto">
                    <button type="button" class="btn btn-danger" @click="removeItemHow(index)">Delete</button>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <label>T & C</label>
                <div class="mb-3">
                  <light-editor v-model="tandc" />
                </div>
              </div>

              <div class="col-3">
                <label>Price (Coins)</label>
                <div class="mb-3">
                  <input type="number" class="form-control" placeholder="Price" v-model.number="price" required />
                </div>
              </div>

              <div class="col-3">
                <label>Reward Type</label>
                <div class="mb-3">
                  <select class="form-control" v-model="type">
                    <option :value="null">Select Option</option>
                    <option value="reward">Normal Reward</option>
                    <option value="subscription">TM Subscription</option>
                    <option value="proSubscription">TM Pro Subscription</option>
                  </select>
                </div>
              </div>

              <div class="col-3">
                <label>Subscription Days</label>
                <div class="mb-3">
                  <input type="number" class="form-control" placeholder="Days" v-model.number="days" required />
                </div>
              </div>

              <div class="col-3">
                <label>Active</label>
                <div class="mt-1 mb-3">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" v-model="active" />
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="h6">Require Data</div>
              </div>
              <div class="col-6 text-right">
                <button type="button" class="btn btn-success btn-sm" @click="addItemReq">Add</button>
              </div>

              <div class="col-12">
                <div v-for="(item, index) in require" :key="index" class="row">
                  <div class="col">
                    <label>Title</label>
                    <input type="text" class="form-control" placeholder="Title of input" v-model="item.title"
                      required />
                  </div>
                  <div class="col">
                    <label>Type</label>
                    <select class="form-control" v-model="item.type">
                      <option :value="null">Select Option</option>
                      <option value="string">String</option>
                      <option value="number">Number</option>
                      <option value="email">Email</option>
                      <option value="mobile">Mobile</option>
                    </select>
                  </div>
                  <div class="col">
                    <label>Min</label>
                    <input type="number" class="form-control" placeholder="Minimum Characters" v-model.number="item.min"
                      required />
                  </div>
                  <div class="col">
                    <label>Max</label>
                    <input type="number" class="form-control" placeholder="Maximum Characters" v-model.number="item.max"
                      required />
                  </div>
                  <div class="col">
                    <label>Placeholder</label>
                    <input type="text" class="form-control" placeholder="Placeholder" v-model="item.placeholder"
                      required />
                  </div>
                  <div class="col">
                    <label>Match Key</label>
                    <input type="text" class="form-control" placeholder="Match Key" v-model="item.matchkey" required />
                  </div>
                  <div class="col">
                    <label>Delete</label>
                    <button type="button" class="btn w-100 btn-danger" @click="removeItemReq(index)">Delete</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <button type="submit" class="btn bg-gradient-info mb-0">
                  <span v-if="isnew">Save Reward</span>
                  <span v-else>Update Reward</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xl-4">
      <RewardPreview :id="id" :logoImage="logoImage" :bgImage="bgImage" :title="title" :description="description"
        :how="how" :tandc="tandc" :price="price" :active="active" :require="require" :action="action" />
    </div>
  </div>
</template>

<script>

import { LightEditor, LightEditorPlugin } from "@hannanmiah/light-editor"

import "@hannanmiah/light-editor/dist/style.css"

import firebase from "firebase";
import SwlAlerts from "../../swlalerts.js";

import RewardPreview from "../../components/rewards/Reward-Preview.vue";

export default {
  props: ["isnew", "duplicat"],
  components: { RewardPreview, LightEditor },

  created() {

    console.log(this.isnew);
    console.log(this.duplicat);

    if (!this.isnew || this.duplicat) {
      this.id = this.$route.params.id;

      const db = firebase.firestore();

      db.collection("rewards")
        .doc(this.id)
        .get()
        .then((doc) => {
          var reward = doc.data();
          this.logoImage = reward.logoImage;
          this.bgImage = reward.bgImage;
          this.title = reward.title;
          this.description = reward.description;
          this.how = reward.how;
          this.tandc = reward.tandc;
          this.price = reward.price;
          this.type = reward.type;
          this.days = reward.days;
          this.active = reward.active;
          this.require = reward.require;
        })
        .catch((error) => {
          SwlAlerts.swlerror(error.message);
        });
    }
  },

  data() {
    return {
      logoImage: null,
      bgImage: null,
      title: null,
      description: null,
      how: [""],
      tandc: null,
      price: 0,
      days: 0,
      type: null,
      active: true,
      require: [{}],
    };
  },
  methods: {

    removeItemHow(index) {
      this.how.splice(index, 1); // Remove the item at the specified index
    },

    addItemHow() {
      this.how.push(""); // Add an empty string to the list
    },

    addItemReq() {
      this.require.push({});
    },

    removeItemReq(index) {
      this.require.splice(index, 1); // Remove the item at the specified index
    },

    generateRandomName() {
      const timestamp = Date.now();
      const randomString = Math.random().toString(36).substring(2, 8);
      return `img_${timestamp}_${randomString}`;
    },

    async logoImageFun(event) {
      const randomName = this.generateRandomName();
      const imageName = `${randomName}.png`;
      this.logoImage = await this.uploadFile(event.target.files[0], imageName);
    },

    async bgImageFun(event) {
      const randomName = this.generateRandomName();
      const imageName = `${randomName}.jpg`;
      this.bgImage = await this.uploadFile(event.target.files[0], imageName);
    },

    async uploadFile(imageUrl, name) {
      var imgurl;
      var user = firebase.auth().currentUser;
      await firebase
        .storage()
        .ref("/rewards/" + user.uid + "/" + name)
        .put(imageUrl)
        .then(async (response) => {
          await response.ref
            .getDownloadURL()
            .then((downloadURL) => {
              imgurl = downloadURL;
            })
            .catch((err) => SwlAlerts.swlerror(err.message));
        });
      return imgurl;
    },

    async savereward() {
      SwlAlerts.swlloading();

      const db = firebase.firestore();

      var path = this.isnew ? db.collection("rewards").doc() : db.collection("rewards").doc(this.id);

      path.set({
        logoImage: this.logoImage,
        bgImage: this.bgImage,
        title: this.title,
        description: this.description,
        how: this.how,
        tandc: this.tandc,
        price: this.price,
        days: this.days,
        type: this.type,
        active: this.active,
        require: this.require,
      })
        .then(() => {
          this.isnew
            ? SwlAlerts.swldone("Reward Successfully Added!")
            : SwlAlerts.swldone("Reward Successfully Updated!");
        })
        .catch((error) => {
          SwlAlerts.swlerror(error.message);
        });
    },
  },
};
</script>

<style scoped>
.form-switch .form-check-input,
.form-check .form-check-input {
  margin-left: 0 !important;
  float: none;
}
</style>
<template>
    <div :class="{ 'modal fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }"
        :id="request_pending" tabindex="-1" role="dialog" :aria-labelledby="request_pending" aria-hidden="true">
        <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title" id="modal-title-default">Request Pending</h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="requestPending">
                        <div class="form-group">
                            <label for="exampleFormControlSelect1">User Message</label>
                            <input type="text" class="form-control" placeholder="User Message" v-model="message"
                                required />
                        </div>
                        <button type="submit" class="btn bg-gradient-success w-100 mb-0">Make Pending</button>
                    </form>
                </div>
                <div class="modal-footer">
                    <button @click="modalclose('toggelRequestPendingModal')" type="button"
                        class="btn btn-link ml-auto mb-0" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div :class="{ 'modal-backdrop fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }"></div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import swlalerts from "../../swlalerts";

export default {
    props: ["active", "txnID", "rrID"],
    data() {
        return {
            message: "Your Reward Request is in Review.",
        };
    },
    created() {
    },
    methods: {
        modalclose(funtext) {
            this.$emit(funtext);
        },
        async requestPending() {

            // Update the Reward Request Status in Reward Request Document

            const db = firebase.firestore();

            const rrRef = db.collection("rewardRequest").doc(this.rrID);

            await rrRef.update({
                status: "Pending",
                message: this.message,
            });

            // Update the Reward Request Status in TXN Document

            const txnRef = db.collection("transaction").doc(this.txnID);

            await txnRef.update({
                status: "In Review",
                message: this.message,
            });

            swlalerts.swldone("Reward Request is Successfully Completed.");

            this.modalclose("toggelRequestPendingModal");

        }
    },
};
</script>
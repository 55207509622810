<template>
  <div class="row">
    <div class="col-xl-4 mb-4" v-for="reward in rewards" :key="reward">
      <RewardPreview :id="reward.id" :logoImage="reward.logoImage" :bgImage="reward.bgImage" :title="reward.title"
        :description="reward.description" :how="reward.how" :tandc="reward.tandc" :price="reward.price"
        :active="reward.active" :require="reward.require" :action="action" />
    </div>
  </div>
</template>

<script>
import RewardPreview from "../../components/rewards/Reward-Preview.vue";

import firebase from "firebase";

export default {
  components: { RewardPreview },
  data() {
    return {
      rewards: [],
      action: true,
    };
  },
  created() {
    const db = firebase.firestore();
    db.collection("rewards")
      .orderBy("price", "asc")
      .onSnapshot((querySnapshot) => {
        var rewardstemp = [];
        querySnapshot.forEach((doc) => {
          let dataWithId = { id: doc.id, ...doc.data() }; // Merge document ID with document data
          rewardstemp.push(dataWithId);
        });
        this.rewards = rewardstemp;
      });
  },
};
</script>

<style></style>